import { connect } from 'react-redux'
import { Card } from '../components/Card'
import { touchDownCard, touchMoveCard, touchUpCard} from "../actions/index";

const mapStateToProps = function(state, ownProps) {
    return {...state, ...ownProps};
}

const mapDispatchToProps = function(dispatch, ownProps) {
    return {
        onMouseDown: (e) => {
            let coords = [e.pageX, e.pageY];
            dispatch(touchDownCard(ownProps.id, coords));
        },

        // mouse moving is handled by the board for more reliable tracking


        onTouchStart: (e) => {
            let coords = [e.touches[0].pageX, e.touches[0].pageY]
            dispatch(touchDownCard(ownProps.id, coords));

            e.stopPropagation(); e.preventDefault();
            e.nativeEvent.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
        },
        onTouchMove: (e) => {
            let offset = [e.touches[0].pageX - ownProps.touchStartCoords[0], e.touches[0].pageY - ownProps.touchStartCoords[1]]
            dispatch(touchMoveCard(ownProps.id, offset));

            e.stopPropagation(); e.preventDefault();
        },
        onTouchEnd: (e) => {
            dispatch(touchUpCard(ownProps.id));

            e.stopPropagation(); e.preventDefault();
            e.nativeEvent.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
        }
    };

};

const InteractiveCard = connect(
    mapStateToProps,
    mapDispatchToProps
)(Card)

export default InteractiveCard
