import styled, {css} from 'styled-components';

export const StyledStoryContainer = styled.div`

& {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  margin: 0 auto;

  ${props => props.fullscreen && css`
      position: fixed;
      background: ${props.backgroundColor};
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      max-width: ${props => {
          let maxWidth = 100;
          let aspect = props.widthRefresh / props.heightRefresh;
          let goal = 16 / 9.0;
          if (props.fullscreen && aspect > 1 && goal < aspect) {
              maxWidth = 100.0 * goal / (aspect);
          }
          return maxWidth + '%';
      }};
  `}
}

.fullscreen__container {
  display: flex;
  cursor: pointer;
  ${props => props.fullscreen && css`
    display: flex;
  `}
  position: absolute;
  width: 44px;
  height: 44px;
  background: transparent;
  bottom: 0;
  right: 0;
  z-index: 29999;

  .fa {
    position: absolute;
    bottom: 7px;
    right: 7px;
    margin: auto;
    color: rgba(198,198,198,1.0);
    font-size: 27px;
    text-shadow: 0px 0px black;
  }
}

.story__container {
    @keyframes background-reveal {
        from {background-size: 0% 100%;}
        to {background-size: 100% 100%;}
    }
    @keyframes opacity-reveal {
        from {opacity: 0;}
        to {opacity: 1;}
    }

    position: relative;
    width: 100%;
    padding-bottom: calc(9 / 16 * 100%);

   & > div {
        position: absolute !important;
        left: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    & > div > div:last-of-type > div {
        cursor: e-resize;
        z-index: 99 !important; // the navigation in mobile has z-index 100
    }
    & > div > div:last-of-type > div:first-of-type {
        cursor: w-resize;
        z-index: 99 !important;
    }

    .story__img-container {
        display: flex;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    .story__img {
        width: auto;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }

    .story__text-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: black;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .story__text-container--top {
        justify-content: flex-start;
    }
    .story__text-container--center {
        justify-content: center;
    }
    .story__text-container--bottom {
        justify-content: flex-end;
    }

    .story__text-container__paragraph, .story__text-container__title {
        margin: 0;
        border-radius: 3px;
        background-repeat: no-repeat;
        background-position: 0 -.1em;
        flex: 1 1;
        display: inline;
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 0px;
        padding-bottom: 2px;
    }

    .story__text-container__title-container {
        margin: 10px 10px 10px 10px;
    }

    .story__text-container__title {
        line-height: 1.4;
        color: white;
        display: inline;
        font-size: 40px;
        font-size: clamp(15px, 4vw, 40px);
        background-size: 0% 100%;
        background-image: -webkit-linear-gradient(left,#fff,#fff);
        background-image: linear-gradient(90deg,#3257d3 0,#3257d3);
        animation-name: background-reveal;
        animation-fill-mode: forwards;
        animation-duration: 1s;

        -webkit-box-decoration-break: slice;
        box-decoration-break: clone;
    }

    .story__text-container__paragraph-container {
        margin: 10px;
    }
    @media screen and (min-width: 500px) {
        .story__text-container__title-container {
            margin: 20px 20px 10px 20px;
            max-width: 50%;
        }
        .story__text-container__paragraph-container {
            margin: 20px;
            max-width: 70%;
        }
    }

    .story__text-container__paragraph {
        line-height: 1.6;
        font-size: 25px;
        font-size: clamp(14px, 2.5vw, 25px);
        background-image: linear-gradient(90deg,#fff 0,#fff);
        background-size: 100% 100%;

        animation-name: opacity-reveal;
        animation-fill-mode: forwards;
        animation-delay: 0s;
        animation-duration: 1s;

        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
    }

}

@media (orientation: portrait) and (max-width: 650px) {
    .fullscreen__container {
      display: flex;
    }
    .story__container {
      padding-bottom: calc(16 / 10 * 100%);

      .story__text-container__title {
        font-size: clamp(23px, 4vw, 40px);
      }
      .story__text-container__paragraph {
        font-size: clamp(17px, 2.5vw, 25px);
      }

      .story__text-container--top {
        justify-content: space-between;
      }
      .story__text-container--center {
        justify-content: space-between;
      }
      .story__text-container--bottom {
        justify-content: space-between;
      }
    }
}
`
