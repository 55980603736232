import {positionToTopCoordinate, topCoordinateToClosestPosition} from "../components/Card";

const dragPosition = function(card, dragOffset) {
    if (!card.paired) {
        // check direction
        const xOffset = dragOffset[0];
        if (card.isA && xOffset < 5 || !card.isA && xOffset > -5) {
            return -1;
        }
    }


    const yOffset = dragOffset[1];
    const top = positionToTopCoordinate(card.position) + yOffset;
    const dragPosition = topCoordinateToClosestPosition(top);

    return dragPosition;
}

const moveCard = function(cards, card, offset) {
    if (!card.dragging) {
        return cards;
    }

    const dragPos = dragPosition(card, offset);
    const cardMoved = card.moved || (Math.abs(offset[0]) > 1 || Math.abs(offset[1]) > 1);

    return cards.map(c => {
        const obj = { ...c };
        if (cardMoved) {
            obj.selected = false;
        }

        if (c.id === card.id) {
            return { ...obj, dragging: true, dragOffset: offset, dropHover: false, moved: cardMoved }
        } else if (!(c.paired && c.position === card.position)&& dragPos === c.position && c.isA != card.isA) {
            return { ...obj, dropHover: true }
        }
        return { ...obj, dropHover: false };
    });
}



const touchUpCard = function(cards, card) {
    if (!card.dragging || !card.touchStartCoords || !card.dragOffset) {
        return cards;
    }

    if (!card.moved) {
        // non drag&drop mode

        const cardToSelect = card;
        const currentSelection = cards.find(c => c.selected);

        if (currentSelection) {
            if (currentSelection.isA === cardToSelect.isA) {
                if (currentSelection === cardToSelect) {
                    // same card -> deselect
                    return cards.map(card => ( card.id === cardToSelect.id ?
                            {
                                ...card, selected: false, dragging: false
                            }
                            : card
                        )
                    );
                } else {
                    // other card in same category selected
                    return cards.map(card => {
                        if (card.id === cardToSelect.id) {
                            return {
                                ...card, selected: true, dragging: false
                            }
                        }
                        if (card.id === currentSelection.id) {
                            return {
                                ...card, selected: false, dragging: false
                            }
                        }
                        return card;
                    });
                }
            } else {
                // connect the cards
                let newState = cards;
                let pair = true;
                if (cardToSelect.position !== currentSelection.position) {
                    // we need to move the card that was at this position, and unpair it if necessary
                    newState = newState.map(card => {
                        if (card.position === cardToSelect.position) {
                            return {...card, paired: false, position: currentSelection.position}
                        }
                        if (card.position == currentSelection.position) {
                            return {...card, paired: false}
                        }
                        return card;
                    });

                    if (currentSelection.paired) {
                        // unpair the previous partner
                        newState = newState.map(card => {
                            return card;
                        });
                    }
                } else {
                    if (cardToSelect.paired && currentSelection.paired) {
                        // unpair already paired cards
                        pair = false
                    }
                }
                return newState.map(card => {
                    if (card.id === cardToSelect.id || card.id === currentSelection.id) {
                        return {...card, selected: false, paired: pair, position: cardToSelect.position, onTop: true, dragging: false}
                    }
                    return {...card, onTop: false};
                })
            }
        }

        return cards.map(card => ( card.id === cardToSelect.id ?
                {
                    ...card, selected: true, dragging: false
                }
                : card
            )
        );
    }

    // drag & drop mode

    const dragPos = dragPosition(card, card.dragOffset);

    if (card.paired && (dragPos === card.position || dragPos === -1)) {
        // check for unpair action
        const unpairThreshold = 20;
        const xOffset = card.dragOffset[0];
        if (card.isA && xOffset < -unpairThreshold || !card.isA && xOffset > unpairThreshold) {
            return cards.map(c => {
                if (c.position === card.position) {
                    return {...c, dragging: false, paired: false, drophover: false, selected: false}
                }
                return { ...c, selected: false };
            });
        }
    }
    if (dragPos === -1 || !cards.find(c => c.isA != card.isA && c.position === dragPos)) {
        // just unselect the card, we don't do anything
        return cards.map(c => {
            return {...c, dropHover: false, dragging: false, selected: false};
        });
    }
    // pair!
    return cards.map(c => {
        if (c.id === card.id) {
            return { ...c, paired: true, dragging: false, position: dragPos, selected: false }
        }
        if (c.position === dragPos) {
            if (c.isA === card.isA) {
                // switch position
                return { ...c, paired: false, position: card.position, selected: false, dragging: false };
            }
            return {...c, paired: true, dropHover: false, selected: false, dragging: false }
        }
        if (c.position === card.position) {
            return {...c, paired: false, selected: false, dragging: false};
        }
        return {...c, dropHover: false, selected: false, dragging: false};
    });
}



const cards = (state = [], action) => {
    switch (action.type) {

        // is used for both mouse and touch
        case 'TOUCH_DOWN_CARD': {
            var card = state.find(c => c.id === action.id);
            return state.map(c => {
                if (c.id === card.id) {
                    if (c.dragging) {
                        // already dragging another one, ignore
                        return c;
                    }
                    return {
                        ...c,
                        dragging: true,
                        moved: false,
                        lastDraggedCard: true,
                        touchStartCoords: action.coords,
                        dragOffset: [0, 0]
                    }
                }
                return {...c, dragging: false, moved: false, lastDraggedCard: false};
            });
        }

        case 'TOUCH_MOVE_CARD': {
            var card = state.find(c => c.id === action.id);
            return moveCard(state, card, action.offset);
        }

        case 'TOUCH_UP_CARD': {
            var card = state.find(c => c.id === action.id);

            return touchUpCard(state, card);
        }

        // does the same as TOUCH_MOVE_CARD, but with the mouse
        case 'BOARD_MOUSE_DRAG': {
            var movingCard = state.find(c => c.dragging);
            if (!movingCard) {
                return state;
            }
            return moveCard(state, movingCard, action.offset);
        }

        // does the same as TOUCH_UP_CARD, but with the mouse
        case 'BOARD_END_MOUSE_DRAG': {
            var movingCard = state.find(c => c.dragging);
            if (!movingCard) {
                return state;
            }

            return touchUpCard(state, movingCard);
        }

        case 'PAIR_CARDS':
            return state.map(c => {
                if (c.id === action.id1 || c.id === action.id2) {
                    return { ...c, paired: true }
                }

                return c;
            })

        case 'SHUFFLE_CARDS':
            if (state.find(c => c.paired)) {
                // cannot shuffle when board already has paired cards
                return state;
            }

            function shuffleArray(array) {
                for (var i = array.length - 1; i > 0; i--) {
                    var j = Math.floor(Math.random() * (i + 1));
                    [array[i], array[j]] = [array[j], array[i]];
                }
                return array;
            }

            let a = state.filter(c => c.isA);
            let b = state.filter(c => !c.isA);

            let shuffledA = shuffleArray(a);
            let shuffledB = shuffleArray(b);

            var positionedA = shuffledA.map(function(c, index) {
                return {...c, position: index }
            });
            var positionedB = shuffledB.map(function(c, index) {
                return {...c, position: index }
            });
            return [...positionedA, ...positionedB];

        case 'CHECK_ANSWERS':
        {
            const correction = action.correction;
            if (!correction) {
                return state;
            }
            const cardsAIdByPosition = {};

            const cardsA = state.filter(c => c.paired && c.isA);
            cardsA.forEach(c => {
                cardsAIdByPosition[c.position] = c.id;
            });

            return state.map(c => {
                if (!c.isA && c.paired && cardsAIdByPosition[c.position] !== undefined) {

                    const partnerA = cardsAIdByPosition[c.position];
                    const correct = correction[partnerA] === c.id ? 'correct' : 'wrong';

                    return {...c, correction: correct};
                }
                return {...c, correction: undefined};
            });
        }
        default:
            return state
    }
}

export default cards
