import React, {useEffect, useState, useCallback} from 'react';
import Stories from "react-insta-stories";
import StoryImage from "./StoryImage";
import {StyledStoryContainer} from "./StyledStoryContainer";

const Story = (props) => {
    const stories = props.imgData.map((data, index, array) => {
        const isLast = index === array.length - 1;

        let totalWords = 0;

        const wordCount = (text) => text.split(' ').length;
        if (data.title) {
            totalWords += wordCount(data.title);
        }
        if (data.text) {
            totalWords += wordCount(data.text);
        }

        let wordsPerMinute = 210; // https://www.researchgate.net/publication/332380784_How_many_words_do_we_read_per_minute_A_review_and_meta-analysis_of_reading_rate
        wordsPerMinute /= 2.0 // we half it as we usually use strong, short phrases
        const wordsPerSecond = wordsPerMinute / 60.0;
        const baseDuration = 3000;
        const readingDuration = (totalWords / wordsPerSecond) * 1000;
        const viewDuration = baseDuration + readingDuration + (index === 0 ? 1200 : 0);
        const minimumDuration = 2400;

        let duration = Math.max(viewDuration, minimumDuration);


        const nextImage = isLast ? null : array[index + 1]['url'];

        return {
            duration: duration,
            content: () => {
                return <StoryImage data={data} nextImg={nextImage} backgroundColor={props.backgroundColor}/>
            }
        }
    });

    const [isFullscreen, setFullscreen] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    const toggleFullscreen = useCallback(() => {
        if (isFullscreen) {
            setTimeout(() => window.scrollTo(window.scrollX, window.savedScrollY), 40);
        } else {
            window.savedScrollY = window.scrollY;
            window.bodyBackground = document.body.style.background;
            window.bodyOverflow = document.body.style.overflow;
            window.htmlOverflow = document.documentElement.style.overflow;
        }
        document.body.classList.toggle('fullscreen');
        document.body.style.overflow = isFullscreen ? window.bodyOverflow : 'hidden';
        document.documentElement.style.overflow = isFullscreen ? window.htmlOverflow : 'hidden';
        document.body.style.background = isFullscreen ? window.bodyBackground : '#111111';
        setFullscreen(!isFullscreen);
    }, [isFullscreen]);

    const handleUserKeyPress = useCallback(event => {
        const {key, keyCode} = event;

        if (key === "Escape" && isFullscreen) {
            toggleFullscreen();
        }
    }, [isFullscreen, toggleFullscreen]);

    useEffect(() => {
        window.addEventListener('keydown', handleUserKeyPress);

        return () => {
            window.removeEventListener('keydown', handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    useEffect(() => {
        let timeoutId = null;
        const resizeToFit = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setWidth(window.innerWidth);
                setHeight(window.innerHeight);
            }, 20);
        };

        resizeToFit();

        // set resize listener
        window.addEventListener('resize', resizeToFit);

        if ("webkitUserSelect" in document.body.style) {
            document.body.style.webkitUserSelect = isFullscreen ? "none" : "";
        }
        if ("webkitTouchCallout" in document.body.style) {
            document.body.style.webkitTouchCallout = isFullscreen ? "none" : "";
        }
        if ("userSelect" in document.body.style) {
            document.body.style.userSelect = isFullscreen ? "none" : "";
        }

        // clean up function
        return () => {
            // remove resize listener
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            window.removeEventListener('resize', resizeToFit);
        }
    }, [isFullscreen]);

    return (
        <StyledStoryContainer fullscreen={isFullscreen} widthRefresh={width} heightRefresh={height}
                              backgroundColor={props.backgroundColor}>
            <div className="story__container">
                <Stories
                    stories={stories}
                    defaultInterval={1500}
                    width={'100%'}
                    height={'100%'}
                    keyboardNavigation={true}
                    loop={true}
                />
            </div>
            <div className={"fullscreen__container"} onClick={toggleFullscreen}>
                <i className={"fa fas fa-icon " + (isFullscreen ? "fa-compress" : "fa-expand")}></i>
            </div>
        </StyledStoryContainer>
    );
}

export default Story;
