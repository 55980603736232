import { combineReducers } from 'redux'
import cards from './cards'
import board from './board'

const assignApp = combineReducers({
    cards,
    board
})

export default assignApp
