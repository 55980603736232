const board = (state = {}, action) => {
    if (state.paused) {
        return {...state}
    }
    switch (action.type) {
        case 'BOARD_START_MOUSE_DRAG': {

            if (state.mouseDragging) {
                return state;
            }
            return { ...state, mouseDragging: true, startCoords: action.coords, dragOffset: [0,0]}
            break;
        }
        case 'BOARD_MOUSE_DRAG': {
            if (!state.mouseDragging) {
                return state;
            }
            return { ...state, dragOffset: action.offset }
            break;
        }
        case 'BOARD_END_MOUSE_DRAG': {
            if (!state.mouseDragging) {
                return state;
            }
            return { ...state, mouseDragging: false, dragOffset: action.offset }
            break;
        }



        case 'CHECK_ANSWERS':
        case 'PAUSE':
            return { ...state, paused: true }
            break;
        default:
            return state;
    }
}

export default board
