import React, {useEffect} from "react";

const StoryImage = (props) => {
    useEffect(() => {
        // preload next image
        if (props.nextImg) {
            let img = new Image();
            img.src = props.nextImg;
        }
    }, [props.nextImg]);

    let classNames = [
        'story__text-container'
    ];

    let alt = props.data.title;
    if (alt === undefined || alt === null || alt.length === 0) {
        alt = props.data.text;
    }

    switch (parseInt(props.data.position)) {
        case 0:
            classNames.push('story__text-container--top');
            break;
        case 1:
            classNames.push('story__text-container--center');
            break;
        case 2:
            classNames.push('story__text-container--bottom');
            break;
        default:
            classNames.push('story__text-container--top');
    }

    return (
        <div className="story__img-container" style={{backgroundColor: props.backgroundColor}}>
            <img className="story__img" src={props.data.image} alt={props.data.image && alt}/>
            <div className={classNames.join(' ')}>
                {props.data.title && <div className="story__text-container__title-container"><p
                    className="story__text-container__title">{props.data.title}</p></div>}
                {props.data.text && <div className="story__text-container__paragraph-container"><p
                    className="story__text-container__paragraph">{props.data.text}</p></div>}
            </div>
        </div>
    );
}

export default StoryImage;
