import React from 'react';
import styled from 'styled-components';
import {cardHeight, cardVerticalGutter} from './Card';
import InteractiveCard from '../containers/InteractiveCard';


class BoardStructure extends React.Component {
    componentDidMount() {

    }

    render() {
        var {cards = [], className, onMouseDown, onMouseMove, onMouseUp, board } = this.props;
;
        return (
            <div className={className}>
                <div className="cardsContainer" onMouseDown={onMouseDown} onMouseMove={e => { onMouseMove(e, board); }} onMouseUp={e => { onMouseUp(e, board); }}>
                    {cards.map((card) => <InteractiveCard key={card.id} {...card} />)}
                </div>
            </div>
        );
    }
}

const boardPaddingTop = 0;
const boardPaddingLeft = 0;

const Board = styled(BoardStructure)`
  position: relative;
  background-color: white;
  
  padding: ${boardPaddingTop}px ${boardPaddingLeft}px;
  
  > div {
    position: relative;
    height: ${props => {
        var highestPosition = 0;
        for(let i=0; i<props.cards.length; i++)
            if (props.cards[i].position > highestPosition)
                highestPosition = props.cards[i].position;
        
        
        return (((cardHeight + (2 * cardVerticalGutter)) * (highestPosition+1)) - 2*cardVerticalGutter) + 'px'
    }};
  }
`;


export default Board