import React from 'react';
import {Provider} from 'react-redux'
import {createRoot} from 'react-dom/client';
import CardBoard from './assign/containers/CardBoard';
import reducer from './assign/reducers';
import {shuffleCards} from './assign/actions';
import {configureStore} from '@reduxjs/toolkit';
import Story from "./stories/Story";

window.assignReactBridge = function (element, initialState) {
    const store = configureStore({reducer: reducer, preloadedState: initialState});
    store.dispatch(shuffleCards());

    const root = createRoot(element)
    root.render(<Provider store={store}>
        <CardBoard/>
    </Provider>)

    return store;
};

window.storyReactBridge = function (element, imgData, backgroundColor) {
    const root = createRoot(element)
    root.render(
        <Story imgData={imgData} backgroundColor={backgroundColor}/>
    );
};
