import { connect } from 'react-redux'
import Board from '../components/Board'

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    cards: state.cards,
    board: state.board,
})

const mapDispatchToProps = (dispatch, ownProps) => function() {
    return {
        onMouseDown: (e) => {
            dispatch({
                type: 'BOARD_START_MOUSE_DRAG',
                coords: [e.pageX, e.pageY]
            });
        },
        onMouseMove: (e, board) => {
            if (!board.mouseDragging || !board.startCoords) {
                return;
            }
            const offset = [e.pageX - board.startCoords[0], e.pageY - board.startCoords[1]];
            dispatch({
                type: 'BOARD_MOUSE_DRAG',
                offset: offset
            });

            e.stopPropagation(); e.preventDefault();
        },
        onMouseUp: (e, board) => {
            if (!board.mouseDragging || !board.startCoords) {
                return;
            }
            //const offset = [e.pageX - board.startCoords[0], e.pageY - board.startCoords[1]];
            dispatch({
                type: 'BOARD_END_MOUSE_DRAG'
            });
        }
    }
}


const CardBoard = connect(
    mapStateToProps,
    mapDispatchToProps
)(Board)

export default CardBoard
