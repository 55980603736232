import React from 'react';
import styled, {css} from 'styled-components';
import cardA from './img/cardA.svg';
import cardB from './img/cardB.svg';
import cardAselected from './img/cardAselection.svg';
import cardBselected from './img/cardBselection.svg';


const cardPadding = 10;
export const cardVerticalGutter = 0; // if increased, check topCoordinateToClosestBoardPosition again
export const cardHeight = 147;

export const topCoordinateToClosestPosition = function (top) {
    const center = top + cardHeight / 2;
    return Math.floor(center / cardHeight);
}

export const positionToTopCoordinate = function (position) {
    return position * cardHeight + (position * (2 * cardVerticalGutter));
}


const Correction = styled.span`
    position: absolute;
    font-size: 30px;
    line-height: ${cardHeight}px;

    ${props => props.correction === 'correct' ? css`
        color: #A1E375;
    ` : css`
        color: #ff6a4c;
    `}
    top: 0;
    bottom: 0;
    left: 105%;
    z-index: 20;
`;


class CardStructure extends React.Component {
    constructor(props) {
        super(props);
    }

    setCardDivRef(element) {
        var wasNull = typeof this.cardDiv === "undefined";
        this.cardDiv = element;
        if (wasNull) {
            var that = this;
            // fix for ios 11.3: set passive: false in touchmove listener to prevent drag & scroll
            this.cardDiv.addEventListener("touchmove", function (e) {
                that.props.onTouchMove(e);
            }, {passive: false});
        }
    }


    render() {
        var {
            content,
            isImage,
            dropHover,
            className,
            onClick,
            onMouseDown,
            onTouchStart,
            onTouchMove,
            onTouchEnd,
            position,
            paired,
            isA,
            selected,
            onTop,
            dragging,
            dragOffset,
            lastDraggedCard,
            correction
        } = this.props;
        let inner = isImage ?
            <img src={content} alt=""/> :
            <div><span>{content}</span></div>;

        // for performance reasons we work with classes and inline styled instead of highly dynamic css
        let classes = [];
        if (isA) {
            classes.push("isA");
        }
        if (paired) {
            classes.push("paired");
        }
        if (selected) {
            classes.push("selected");
        }
        if (onTop) {
            classes.push("onTop");
        }
        if (dragging) {
            classes.push("dragging");
        }
        if (lastDraggedCard) {
            classes.push("lastDraggedCard");
        }
        if (dropHover) {
            classes.push("dropHover");
        }


        let top = positionToTopCoordinate(position);

        let marginTop = 0;
        let marginLeft = 0;
        if (dragging) {
            marginLeft = dragOffset[0];
            top += dragOffset[1];
        }


        const classString = classes.join(" ");

        var correctionInfo = correction ?
            <Correction correction={correction}>{correction === 'correct' ? '✓' : '✘'}</Correction> : null;

        return (
            <div className={className + " " + classString} ref={this.setCardDivRef.bind(this)} onClick={onClick}
                 onMouseDown={onMouseDown} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}
                 style={{top: top + 'px', marginTop: marginTop + 'px', marginLeft: marginLeft + "px"}}>
                <div className="selection"></div>
                <div className="inner">
                    <div>
                        {inner}
                    </div>
                </div>
                {correctionInfo}
            </div>
        );
    }
}


const puzzleKnobCenterWidthPercentage = 8.7;
const puzzleHoleCenterWidthPercentage = 8.1;
const pairedScale = 1; // 0.86

const cardWidthPercentage = 46;

const knobCenterGlobalPercentage = 100 * (cardWidthPercentage / 100 * puzzleKnobCenterWidthPercentage / 100);
const holeCenterGlobalPercentage = 100 * (cardWidthPercentage / 100 * puzzleHoleCenterWidthPercentage / 100);

const pairedDistanceFactor = 1.1; // smaller means closer, where 1 == together


// percentage of shadow in the card
const leftShadowPercentage = 2.95;
const rightShadowPercentage = 3.8;

const leftShadowGlobalPercentage = 100 * (cardWidthPercentage / 100 * leftShadowPercentage / 100);
const rightShadowGlobalPercentage = 100 * (cardWidthPercentage / 100 * rightShadowPercentage / 100);

const contentPaddingTop = 17;
const contentPaddingBottom = 19;


export const Card = styled(CardStructure)`
    padding: 0;
    width: ${cardWidthPercentage}%;
    height: ${cardHeight}px;
    position: absolute;
    font-size: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;
    transition: top 0.5s, left 0.5s, right 0.5s, margin-top 0.5s, margin-left 0.5s, transform 0.15s;
    -webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;

    background-size: 100% 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    z-index: 0;

    height: ${cardHeight}px;
    touch-action: none;

    transform: scale(1.00);

    &.isA {
        left: ${-leftShadowGlobalPercentage}%;
        //image-rendering: -webkit-optimize-contrast;
        background-image: url(${cardA});

        &.dropHover {
            left: ${-leftShadowGlobalPercentage + 2}%;
        }

        .selection {
            background-image: url(${cardAselected});
        }

        &.paired {
            left ${50 - cardWidthPercentage + (knobCenterGlobalPercentage / pairedDistanceFactor)}%;
        }
        .inner {
            padding-left: calc(${leftShadowPercentage}% + 5px);
            padding-right: calc(13.7% + 4px);

            @media (min-width: 768px) {
                padding-left: calc(${leftShadowPercentage}% + 10px);
                padding-right: calc(13.7% + 8px);
            }
        }


    }

    &:not(.isA) {
        background-image: url(${cardB});
        //right: -${(rightShadowPercentage / 100) * cardWidthPercentage}%;
        left: calc(${100 - cardWidthPercentage + rightShadowGlobalPercentage}%);

        &.dropHover {
            left: calc(${100 - cardWidthPercentage + rightShadowGlobalPercentage - 2}%);
        }
         .selection {
            background-image: url(${cardBselected});
        }

        &.paired {
            //right: calc(50% - ${cardWidthPercentage}% + ${holeCenterGlobalPercentage / pairedDistanceFactor}%);
            left: calc(${50 - (holeCenterGlobalPercentage / pairedDistanceFactor)}%);
        }

        .inner {
            padding-left: calc(13% + 4px);
            padding-right: calc(${rightShadowPercentage}% + 5px);

            @media (min-width: 768px) {
                padding-left: calc(13% + 8px);
                padding-right: calc(${rightShadowPercentage}% + 10px);
            }
        }
    }

    :hover {
        transform: scale(1.025);
    }

    &.paired {
        transform: scale(${pairedScale});
    }

    &.onTop {
        z-index: 9;
    }
    &.dragging, &.lastDraggedCard {
        z-index: 10;
    }

    &.dragging {
        transition: transform 0.15s;
    }

    .selection {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    &.selected {
        //filter: drop-shadow( 0px 0px 7px #1db5f7);
        z-index: 15;

        .selection {
            display: block;
        }
    }

    &.dropHover {
        //filter: drop-shadow(0px 0px 3px #1db5f7);
                .selection {
            display: block;
        }
    }

    .inner {
        pointer-events: none;
        -webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;
        width: 100%;
        height: 100%;
        position: relative;

        padding-top: ${contentPaddingTop}px;
        padding-bottom: ${contentPaddingBottom}px;
        > div {
            width: 100%;
            height: 100%;
            overflow: hidden;
            text-align: center;
            line-height: ${cardHeight - contentPaddingBottom - contentPaddingTop}px;
            span {
                font-size: 13px;
                line-height: 110%;
                height: 100%;
                vertical-align: middle;
                display: inline-block;
                word-break: break-word;

                @media screen and (min-width: 700px) {
                    font-size: 15px;
                }

                @media screen and (min-width: 1100px) {
                    font-size: 19px;
                }
             }
            img {
                display: inline-block;
                max-width: 100%;
                max-height: 100%;
                vertical-align: middle;
                pointer-events: none;
            }
        }
    }
`;
