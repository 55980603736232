export const selectCard = (id) => ({
    type: 'SELECT_CARD',
    id: id
})

export const touchDownCard = (id, coords) => ({
    type: 'TOUCH_DOWN_CARD',
    id: id,
    coords: coords
})

export const touchMoveCard = (id, offset) => ({
    type: 'TOUCH_MOVE_CARD',
    id: id,
    offset: offset
})


export const touchUpCard = (id) => ({
    type: 'TOUCH_UP_CARD',
    id: id
})


export const pairCards = (id1, id2) => ({
    type: 'PAIR_CARDS',
    id1: id1,
    id2: id2
})

export const shuffleCards = () => ({
    type: 'SHUFFLE_CARDS'
})
